import React, { useState, useEffect, useRef, useCallback } from "react";
import Layout from "components/Layout";
import { TextField, Modal, InputLabel, MenuItem, FormControl, Select, Box, Button, Checkbox, FormControlLabel, Typography, FormLabel, Radio, RadioGroup, FormGroup } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { editMessage, getApplications, postMessage } from "../utils/apiRequestBuilder";
import DOMPurify from "dompurify";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

import Lottie from 'react-lottie';
import animationData from '../assets/animations/loader';
import { getRecipients } from "utils/apiRequests";
import { useLocation, useNavigate } from "react-router-dom";


const ListView = (props) => {

    const listOfItems = props.items;
    const listTitle = props.title;
    const selectedItems = props.valuesArray;
    const handleCheckBoxCLick = (item) => {

        const itemSelectedIndex = selectedItems.findIndex((itemObj) => itemObj.value == item.value);

        if (itemSelectedIndex != -1) {
            props.updateFunction(
                selectedItems.filter(itemObj => itemObj.value !== item.value)
            )
        } else {
            props.updateFunction([...selectedItems, item])
        }
    }

    const isSelected = (item) => {
        const itemSelectedIndex = selectedItems.findIndex((itemObj) => itemObj.value == item.value);
        if (itemSelectedIndex != -1) {
            return true;
        }
        return false;
    }

    const selectAll = () => {
        const allItemOptions = [...listOfItems];
        props.updateFunction(allItemOptions);
    }

    const clearAll = () => {
        props.updateFunction([]);
    }

    const disableClearAll = () => {
        if (selectedItems.length > 0) {
            return false;
        }
        return true;
    }

    const disableSelectAll = () => {
        const allItemOptions = [...listOfItems];
        if (selectedItems.length == allItemOptions.length) {
            return true;
        }
        return false;
    }

    return (
        <Box sx={{ marginRight: '5em'}}>
            <Box sx={{height: '250px', overflowY: 'scroll', minWidth: '300px'}}>
                <FormLabel component="legend">{listTitle}</FormLabel>
                <FormGroup>
                    {listOfItems.map((item,index) => (
                        <FormControlLabel key={index + 'rec'} sx={listCheckBoxStyle} control={<Checkbox checked={isSelected(item)} onChange={() => handleCheckBoxCLick(item)} name={item.value} />} label={item.label} />
                    ))}
                </FormGroup>
            </Box>

            <Box style={{ flexDirection: "row", justifyContent: 'space-between'}}>
                <Button disabled={disableSelectAll()} size="small" variant="contained" color="primary" onClick={() => selectAll()}>Select All</Button>
                <Button sx={{ marginLeft: 2 }} disabled={disableClearAll()} size="small" variant="contained" color="primary" onClick={() => clearAll()}>Clear All</Button>

            </Box>


        </Box>

    )
}

const listCheckBoxStyle = {
    minWidth: '300px',
    "&:hover": {
      borderColor: "red",
      backgroundColor: "#F5F5F5",
      span: {
          color: "red"
      }
    }
}

const NewMessagePage = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { state }  = location;
    const { messageData, isEdit, messageId } = state || {};
    const [subject, setSubject] = useState(messageData?.subject || '');
    const [recipients, setRecipients] = useState(messageData?.clients || []);
    const [recipientsOptions, setRecipientsOptions] = useState([]);
    const [applicationOptions, updateApplicationOptions] = useState([]);
    const [applications, setApplications] = useState(messageData?.applications || []);
    const [expiryDate, setExpiryDate] = useState(messageData?.expires_at || '');
    const [message, setMessage] = useState(messageData?.message_text || '');
    const [messageSending, setMessageSending] = useState(false);
    const [messageType, setMessageType] = useState(messageData?.type || 'normal');
    const [messageSeverity, setMessageSeverity] = useState(messageData?.severity || 'info');
    const [attachedFiles, setAttachedFiles] = useState(messageData?.attachments || []);
    const [filesToBeRemoved, setFilesToBeRemoved] = useState([]);

    const [submissionDialogVis, setSubmissionDialogVis] = React.useState(false);
    const [submissionSuccess, setSubmissionSuccess] = React.useState(false);

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if(timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay)
        }
    }

    const debounceMessageChange = useCallback(debounce((message) => setMessage(message), 300), []);
    const fetchRecipients = async () => {
      const response = await getRecipients();
      setRecipientsOptions(response.data.map((data) => ({ value: data.Id, label: data.Name })));
    }

    const messageTypesOptions = [
        { value: 'normal', label: 'Normal' },
        { value: 'priority', label: 'Priority' }
    ];

    const messageSeverityOptions = [
        { value: 'info', label: 'Normal' },
        { value: 'warning', label: 'Warning' },
        { value: 'error', label: 'Error' }
    ];


    useEffect(() => {
        fetchApplications();
        fetchRecipients();
    }, [])



    const textEditorToolbarOptions = [
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ];

    const messageEditorModule = {
        toolbar: textEditorToolbarOptions
    }

    const fetchApplications = async () => { // Setting applications list from database
        const [request, options] = await getApplications();
        const response = await fetch(request, options);
        if (response.status === 200) {
            const json = await response.json();
            const applications = [];

            json.data.forEach((app) => applications.push({'value': app.application_id, 'label': app.description }) );

            updateApplicationOptions(applications);
        } else {
            console.log('Failed to fetch applications, Error: ' + response.status);
        }
    }

    const submitMessage = async () => {

        setMessageSending(true);
        setSubmissionDialogVis(true);

        const infinityDate = '9000-01-01 00:00:00';
        const formatedExpiryDate = expiryDate == Infinity ? infinityDate : moment(expiryDate).format("YYYY-MM-DD HH:mm:ss");
        const content = DOMPurify.sanitize(message);
        const santizedSubject = DOMPurify.sanitize(subject);
        
        const all_recipients = recipients.map(recipient_info => recipient_info.value).join(", ");
        const all_applications = applications.map(application_info => application_info.value).join(", ");

        const message_info = {
            subject: santizedSubject,
            message: content,
            clientId: all_recipients,
            applicationId: all_applications,
            type: messageType,
            severity: messageSeverity,
            expiresAt: formatedExpiryDate
        }

        const formData = new FormData();
        for (let key in message_info) {
            formData.append(key, message_info[key])
        }
        attachedFiles.forEach((file, index) => {
            formData.append('files', file);
        });

        if(isEdit) {
            formData.append('filesToRemove', filesToBeRemoved.map((file) => file).join(","));
            const [request, options] = await editMessage(formData, messageId);
            const response = await fetch(request, options);
            if (response.status === 200) {
                setSubmissionSuccess(true);
                navigate("/manage-messages")
            } else {
                setSubmissionSuccess(false);
            }
            setMessageSending(false);
        } else {
            const [request, options] = await postMessage(formData);
            const response = await fetch(request, options);
            if (response.status === 201) {
                setSubmissionSuccess(true);
            } else {
                setSubmissionSuccess(false);
            }
            setMessageSending(false);
        }
    }

    const resetMessageParameters = () => {

        setSubject('');
        setRecipients([]);
        setApplications([]);
        setExpiryDate('');
        setMessage('');
        setMessageType('normal');
        setMessageSeverity('normal');
        setAttachedFiles([]);

    }

    
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setAttachedFiles((prevFiles) => [...prevFiles, ...files]);
    };
    
    const handleRemoveFile = (fileName) => {
        setAttachedFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
        if(isEdit) {
            const findFile = attachedFiles.find(file => file.name === fileName);
            setFilesToBeRemoved((prevFiles) => [...prevFiles, findFile.id]);
        }
    };

    const handleNoExpiryDate = () => {
        
        if (expiryDate == Infinity) {
            setExpiryDate(null);
        } else {
            setExpiryDate(Infinity);
        }
    }

    const enableSubmissionButton = () => { // used to determine if to enable or disable submission button

        const messageSanitized = DOMPurify.sanitize(message);
        const subjectSanitized = DOMPurify.sanitize(subject);

        const recipientsSelected = recipients.length > 0 ? true : false;
        const applicationSelected = applications.length > 0 ? true : false;
        const subjectProvided = subjectSanitized?.length > 0 ? true : false;
        const expiryDateValid = moment(expiryDate).isValid() || expiryDate == Infinity ? true : false;
        const messageProvided = messageSanitized?.length > 0 ? true : false;

        if (recipientsSelected && applicationSelected && subjectProvided && expiryDateValid && messageProvided) {
            return true;
        } else {
            return false;
        }
    }

    const SubmissionModal = () => {

       
        const handleClose = () => {
            setSubmissionDialogVis(false);

            if (submissionSuccess) { // reset message parameters when message was submitted succcesful
                resetMessageParameters();
                setSubmissionSuccess(false);
            }
        }

        const LoadingView = () => {
            const defaultOptions = {
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
            };

            return (
                <Box>
                    <Lottie 
                        options={defaultOptions}
                        height={60}
                        width={60}
                    />
                    <Typography id="message-loading-title" variant="subtitle1">
                        Message Being Sent...
                    </Typography>
                </Box>
            )
        };

        const ResultsView = () => {
            return (
                <Box>
                    { submissionSuccess ? <CheckCircleIcon style={{ color: 'green', fontSize: 40 }} /> : <CancelIcon style={{ color: 'red', fontSize: 40  }} /> }
                    <Typography id="message-modal-title" variant="body1">
                        { submissionSuccess ? 'Message was submitted successfully.' : 'Sorry, there was an error submitting one or more of your messages.'}
                    </Typography>
                </Box>
            )
        }
      
        return (
            <Modal
                open={submissionDialogVis}
                onClose={handleClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '0px solid #000',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#616161',
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    { messageSending ? <LoadingView /> : <ResultsView /> }
                </Box>
            </Modal>
        );
      
    }
    
      

    return (
        <Layout>

            <Box component="section" sx={{ marginLeft: 5, marginTop: 5 }}>
                <Typography variant="h5">New Message/Edit Message</Typography>

                <SubmissionModal />


                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, maxWidth: '80%', justifyContent: 'space-between'}}>


                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%', marginBottom: '20px'}}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography variant="h6" sx={{ marginRight: 2, width: '120px'}} >Type: </Typography>
                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <Select
                                    labelId="select-type-label"
                                    id="select-type"
                                    value={messageType}
                                    onChange={(e) => setMessageType(e.target.value)}
                                >
                                    {messageTypesOptions.map((type) => (
                                        <MenuItem value={type.value}>{type.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 4}}>
                            <Typography variant="h6" sx={{ marginRight: 2, justifyContent: 'center', alignItems: 'center'}}>Severity:</Typography>
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                <Select
                                    labelId="select-severity-label"
                                    id="select-severity"
                                    value={messageSeverity}
                                    onChange={(e) => setMessageSeverity(e.target.value)}
                                >
                                    {messageSeverityOptions.map((type) => (
                                        <MenuItem value={type.value}>{type.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <Typography variant="h6" sx={{ marginRight: 2, width: '120px'}}>Subject:</Typography>
                        <TextField
                            required
                            sx={{ width: "90%", fontSize: 12, fontWeight: 'bold'}}
                            label="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </Box>
                    
                        
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                        <Typography variant="h6" sx={{ marginRight: 2, width: '120px'}}>To:</Typography>

                        <Box>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="client"
                            >
                                <FormControlLabel value="client" control={<Radio />} label="Select Clients" />
                                <FormControlLabel disabled value="group" control={<Radio />} label="Select Groups" />
                            </RadioGroup>

                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: 3}}>
                                <ListView items={recipientsOptions} title="Recipients" valuesArray={recipients} updateFunction={setRecipients} />
                                <ListView items={applicationOptions} title="Application" valuesArray={applications} updateFunction={setApplications} />
                            </Box>
                            

                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 5, alignItems: 'center', width: '100%', zIndex: 10 }}>
                        <Typography variant="h6" sx={{ marginRight: 2, width: '120px'}}>Expiry:</Typography>
                        <DatePicker
                            selected={expiryDate == Infinity ? '' : expiryDate} // ofcourse i can't pass Infinity as a value for the date picker
                            disabled={expiryDate == Infinity ? true : false}
                            onChange={(date) => setExpiryDate(date)}
                            dateFormat="yyyy/MM/dd"
                            customInput={<TextField label="Expiry Date"  />}
                        />
                         <FormControlLabel sx={{ minWidth: '300px', marginLeft: '1em' }} control={<Checkbox onChange={() => handleNoExpiryDate()} />} label={'No Expiry Date'} />

                    </Box>

                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 5, width: '100%', minHeight: '250px', zIndex: 5 }}>
                        <Typography variant="h6" sx={{ marginRight: 4, width: '120px'}}>Message:</Typography>
                        <ReactQuill style={{ width: '100%', height: '40vh', marginBottom: '5em', padding: 0}} modules={messageEditorModule} theme="snow" value={message} onChange={debounceMessageChange} placeholder="Enter Message" />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <Typography variant="h6" sx={{ marginRight: 2, width: '120px'}}>Attachments:</Typography>
                        <Box>
                            <Button
                                variant="contained"
                                component="label"
                                size="small"
                            >
                                Attach Files
                                <input type="file" id="files" name="files" multiple hidden onChange={handleFileChange} />
                            </Button>
                            <List>
                                {attachedFiles.map((file, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={file.name} />
                                        <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={() => handleRemoveFile(file.name)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>


                    <Box sx={{ marginLeft: 'auto' }}>
                        <Button size="large" variant="contained" color="primary" disabled={!enableSubmissionButton()} endIcon={<SendIcon />} onClick={() => submitMessage()}>{isEdit ? 'Update Email' : 'Send Email'}</Button>
                    </Box>
                    

                </Box>     
                
                
            </Box>



        </Layout>
    )

}

export default NewMessagePage;